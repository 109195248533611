import React, { useState } from "react";
import { useInterval } from "shared/utils/hooks";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Touchable } from "shared/components/Touchable";
import { Colors, Radius, Shadows, Spacing } from "shared/utils/theme";
import { Button } from "shared/components/Button";
import { reloadApp } from "shared/utils/platform";
import { SectionData } from "../Section";

const minute = 60;
const hour = 60 * minute;
const day = 24 * hour;

function makeCountdownText(date: Date) {
  const now = new Date().getTime();
  const toDate = date.getTime();
  const totalSeconds = Math.floor((toDate - now) / 1000);
  const days = Math.floor(totalSeconds / day);
  const hours = Math.floor((totalSeconds % day) / hour);
  const minutes = Math.floor((totalSeconds % hour) / minute);
  const seconds = Math.floor(totalSeconds % minute);
  return {
    days: Math.max(0, days),
    hours: Math.max(0, hours),
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
    totalSeconds,
  };
}

function NumberBox({ number, label }) {
  return (
    <View style={Shadows.card}>
      <View
        style={{
          backgroundColor: Colors.background,
          margin: Spacing.betweenHalf,
          width: 88,
          height: 88,
          borderRadius: Radius.medium,
          overflow: "hidden",
        }}
      >
        <View
          style={{
            alignItems: "center",
            paddingVertical: 4,
            backgroundColor: Colors.ios.indigo,
          }}
        >
          <Text type="subtitleStrong" color={Colors.generic.white}>
            {label}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Colors.transparent.primary[1],
          }}
        >
          <Text
            type="largeTitle"
            style={{ fontVariant: ["tabular-nums"] }}
            color={Colors.primary}
          >
            {number}
          </Text>
        </View>
      </View>
    </View>
  );
}

interface Config {
  date: Date;
  title: string;
  subtitle?: string;
  reloadButton?: string;
  complete?: string;
}

function CountdownSection({ section }: { section: SectionData<Config> }) {
  const date = new Date(section?.config?.date);
  const [countdown, setCountdown] = useState(makeCountdownText(date));
  const showDays = countdown.totalSeconds > day - 60;
  const showHours =
    countdown.totalSeconds > hour - 1 && countdown.totalSeconds < day;
  const showMinutes =
    countdown.totalSeconds > minute - 1 && countdown.totalSeconds < day - 60;
  const showSeconds = countdown.totalSeconds < hour - 30;
  useInterval(
    () => {
      setCountdown(makeCountdownText(date));
    },
    showSeconds ? 1000 : 60000
  );
  return (
    <View style={{ alignItems: "center" }}>
      <Touchable onLongPress={reloadApp}>
        <Text style={{ marginVertical: Spacing.between }} type="longTitle">
          {countdown.totalSeconds >= 1
            ? section?.config?.title
            : section?.config?.complete}
        </Text>
      </Touchable>
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        {showDays && <NumberBox number={countdown.days} label="Tage" />}
        {showHours && <NumberBox number={countdown.hours} label="Stunden" />}
        {showMinutes && (
          <NumberBox number={countdown.minutes} label="Minuten" />
        )}
        {showSeconds && (
          <NumberBox number={countdown.seconds} label="Sekunden" />
        )}
      </View>
      {countdown.totalSeconds >= 1 ? (
        <Text
          style={{ marginVertical: Spacing.between, textAlign: "center" }}
          type="subtitle"
        >
          {section?.config?.subtitle}
        </Text>
      ) : (
        <View
          style={{
            margin: Spacing.between,
            alignSelf: "stretch",
          }}
        >
          {section?.config?.reloadButton && (
            <Button
              size="big"
              outline={true}
              pill={true}
              foreground={Colors.primary}
              background={Colors.background}
              children={section?.config?.reloadButton}
              onPress={() => {
                reloadApp();
              }}
            />
          )}
        </View>
      )}
    </View>
  );
}

export { CountdownSection };
