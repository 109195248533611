import React from "react";
import { View } from "react-native";
import { Colors } from "shared/utils/theme";
import { Text } from "shared/components/Text";
import { Spacing } from "shared/utils/theme";
import { SectionData } from "../Section";
import { formatTimeAgo } from "shared/utils/formatDate";
import { Link } from "../../Link";
import { CloudinaryImage } from "../../Image";
import { Button } from "shared/components/Button";
function LatestPosts({ section: { items } }: { section: SectionData<{}> }) {
  return (
    <View>
      {items &&
        items.map((e) => (
          <Link resourceType="occurrence" resourceId={e.id} key={e.id}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <CloudinaryImage
                width={40}
                height={40}
                style={{
                  borderRadius: 4,
                  marginRight: 8,
                  overflow: "hidden",
                  marginVertical: 4,
                }}
                image={{
                  handle: e.event.coverImage_handle,
                  blurhash: e.event.hash,
                }}
              />
              <Text
                type="captionStrong"
                numberOfLines={1}
                style={{ flex: 2, paddingRight: 4 }}
              >
                {e.event.name}
              </Text>

              <Text style={{ flex: 1, textAlign: "right" }} type="captionLight">
                {formatTimeAgo(new Date(e.updated_at))}
              </Text>
            </View>
          </Link>
        ))}
      <Button
        iconLeft="k-tab-profiles"
        iconRight="f-chevron-right"
        background={Colors.link}
        foreground={Colors.generic.white}
        style={{ marginLeft: 124, marginTop: Spacing.between }}
        as={Link}
        onPress={() => {}}
        // @ts-ignore
        resourceType="timeline"
      >
        Profile entdecken
      </Button>
    </View>
  );
}

export { LatestPosts };
