import React, { useMemo } from "react";
import { Platform, View } from "react-native";
import { Spacing } from "shared/utils/theme";
import { FlatList } from "react-native";
import {
  ROW_CARD_WIDTH,
  FEATURED_CARD_WIDTH,
  FeaturedCard,
  RowCard,
} from "shared/components/EventCard";
import { ProfileRow } from "shared/components/ProfileRow";
import { SectionData } from "../Section";

function Seperator() {
  return <View style={{ width: Spacing.between, height: Spacing.between }} />;
}

function OccurrencePicks({
  section,
}: {
  section: SectionData<{ min_events?: number }>;
}) {
  return (
    <FeatureRowView
      data={section.items}
      minEvents={section?.config?.min_events || 3}
    />
  );
}

function CollagePicks({
  section,
}: {
  section: SectionData<{ min_events?: number }>;
}) {
  const data = useMemo(
    // @ts-ignore
    () => section?.items[0]?.occurrences?.map(({ occurrence }) => occurrence),
    [section.items]
  );
  return (
    <FeatureRowView data={data} minEvents={section?.config?.min_events || 3} />
  );
}

function FeatureRowView({ data, minEvents = 3 }) {
  if (data.length < minEvents) {
    throw "Not enough events to show section";
  }
  return (
    <FlatList
      initialNumToRender={3}
      style={{
        overflow: Platform.select({ default: "visible", web: "scroll" }),
      }}
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      decelerationRate="fast"
      snapToInterval={Platform.select({
        ios: FEATURED_CARD_WIDTH + Spacing.between,
        android: undefined,
      })}
      snapToAlignment={"start"}
      data={data}
      keyExtractor={(i, index) => String(i.id) + String(index)}
      ItemSeparatorComponent={Seperator}
      getItemLayout={(data, index) => ({
        length: FEATURED_CARD_WIDTH,
        offset: FEATURED_CARD_WIDTH * index,
        index,
      })}
      renderItem={({ item, index }) => {
        if (item && item.event) {
          return <FeaturedCard data={item} prefetch={false} />;
        } else {
          return null;
        }
      }}
    />
  );
}

function OccurrenceRow({
  section,
}: {
  section: SectionData<{ min_events?: number }>;
}) {
  return (
    <EventRowView
      minEvents={section?.config?.min_events || 3}
      data={section.items}
    />
  );
}

function CollageRow({
  section,
}: {
  section: SectionData<{ min_events?: number }>;
}) {
  const data = useMemo(
    // @ts-ignore
    () => section?.items[0]?.occurrences?.map(({ occurrence }) => occurrence),
    [section.items]
  );
  return (
    <EventRowView minEvents={section?.config?.min_events || 3} data={data} />
  );
}

function EventRowView({ data, minEvents = 3 }) {
  if (!data || data.length < minEvents) {
    throw "Not enough events to show section";
  }
  return (
    <FlatList
      initialNumToRender={3}
      style={{
        overflow: Platform.select({ default: "visible", web: "scroll" }),
      }}
      showsHorizontalScrollIndicator={false}
      horizontal={true}
      decelerationRate="fast"
      snapToInterval={Platform.select({
        ios: ROW_CARD_WIDTH + Spacing.between,
        android: undefined,
      })}
      keyExtractor={(i, index) => String(i.id) + String(index)}
      snapToAlignment={"start"}
      data={data}
      ItemSeparatorComponent={Seperator}
      getItemLayout={(data, index) => ({
        length: ROW_CARD_WIDTH,
        offset: ROW_CARD_WIDTH * index,
        index,
      })}
      renderItem={({ item, index }) => {
        if (item && item.event) {
          return (
            <View accessible={true} accessibilityRole="button">
              <RowCard data={item} prefetch={false} />
              <ProfileRow
                profile={item.event.profile}
                style={{
                  paddingLeft: Spacing.between,
                  paddingTop: Spacing.betweenHalf,
                  maxWidth: 320,
                }}
              />
            </View>
          );
        } else {
          return null;
        }
      }}
    />
  );
}

export { CollagePicks, CollageRow, OccurrencePicks, OccurrenceRow };
