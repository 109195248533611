import gql from "graphql-tag";

const CATEGORY_MENU_FRAGMENT = gql`
  fragment CategoryMenuFragment on eventTypes {
    eventType
    icon
    shortLabel
    gradientIndex
    hidden
    sort_index
    menu_index
  }
`;

export { CATEGORY_MENU_FRAGMENT };
