import gql from "graphql-tag";
import { SMALL_PROFILE_ROW_FRAGMENT_ON_OCCURRENCE } from "shared/components/ProfileRow/fragment";
import { BLURHASH_ON_EVENT } from "./fragment_blurhash";

const IMPROVE_PARTIAL_FRAGMENT = gql`
  fragment ImprovePartialFragment on occurrences {
    event {
      placeInfo {
        id: placeID
        open_url
        name
        address
      }
    }
  }
`;

const EVENT_CARD_FRAGMENT = gql`
  fragment EventCardFragment on occurrences {
    ...SmallProfileRowFragmentOnOccurrence
    ...ImprovePartialFragment
    startDate
    created_at
    updated_at
    id
    cancelled

    event {
      created_at
      id
      name
      statePublished
      type {
        eventType
        icon_slug
        shortLabel
      }
      occurrences(order_by: { startDate: asc }) {
        id
        startDate
      }
      coverImage_handle
      poster_handle: data(path: "poster_handle")
      ...BlurhashOnEvent
    }
  }
  ${BLURHASH_ON_EVENT}
  ${SMALL_PROFILE_ROW_FRAGMENT_ON_OCCURRENCE}
  ${IMPROVE_PARTIAL_FRAGMENT}
`;

export { EVENT_CARD_FRAGMENT };
