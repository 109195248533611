import React from "react";
import { View } from "react-native";
import { Spacing } from "shared/utils/theme";
import { CalendarFilter } from "shared/components/CalendarFilter";

import { SectionData } from "../Section";

function CategoriesCalendar({
  section,
}: {
  section: SectionData<{ limit: number }>;
}) {
  return <CalendarFilter eventTypes={section.items} />;
}

export { CategoriesCalendar };
