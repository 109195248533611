import gql from "graphql-tag";
import {
  PROFILE_ROW_FRAGMENT_ON_OCCURRENCE,
  PROFILE_ROW_FRAGMENT,
} from "shared/components/ProfileRow/fragment";
import { EVENT_CARD_FRAGMENT } from "shared/components/EventCard/fragment";
import { NOTICE_FRAGMENT } from "shared/components/Notice/fragment";
import { CATEGORY_MENU_FRAGMENT } from "shared/components/CategoryMenu/fragment";

const FEED_SECTION_FRAGMENT = gql`
  fragment FeedSectionFragment on feed {
    isResource
    id
    order
    layout
    name
    config
    items
    itemsType
    re_resourceType
    rd_notice {
      ...NoticeFragment
    }
    rd_eventType {
      ...CategoryMenuFragment
    }
    rd_collage {
      id
      name
      internal
      occurrences(
        where: {
          occurrence: {
            startDate: { _gte: "now()" }
            cancelled: { _eq: false }
          }
        }
        order_by: [{ order: asc }, { occurrence: { startDate: asc } }]
      ) {
        id
        occurrence {
          ...EventCardFragment
          ...ProfileRowFragmentOnOccurrence
        }
      }
    }
    rd_profile {
      ...ProfileRowFragment
    }
    rd_occurrence {
      ...EventCardFragment
      ...ProfileRowFragmentOnOccurrence
    }
  }
  ${EVENT_CARD_FRAGMENT}
  ${CATEGORY_MENU_FRAGMENT}
  ${PROFILE_ROW_FRAGMENT_ON_OCCURRENCE}
  ${PROFILE_ROW_FRAGMENT}
  ${NOTICE_FRAGMENT}
`;

export { FEED_SECTION_FRAGMENT };
