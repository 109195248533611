import React from "react";
import { View } from "react-native";
import { Body } from "shared/components/Body";
import { Dynamic, Radius, Shadows, Spacing } from "shared/utils/theme";
import { Text } from "shared/components/Text";
import { CloudinaryImage } from "shared/components/Image";
import { Colors } from "shared/utils/theme";
import { Icon } from "shared/components/Icon";
import { Link } from "shared/components/Link";
import { EventType } from "shared/data/EventType";

export function NewMovies({ text, data }) {
  return (
    <Link resourceType="categories" resourceId={EventType.Movie}>
      <View style={Shadows.card}>
        <View
          style={{
            overflow: "hidden",
            borderRadius: Radius.round,
            backgroundColor: Colors.card,
          }}
        >
          {data.images && data.images.length > 0 && (
            <View style={{ flexDirection: "row" }}>
              {data.images.map((i) => (
                <CloudinaryImage
                  key={i}
                  image={{ handle: i }}
                  height={280}
                  width={(Dynamic.width - Spacing.between * 2) / 4}
                />
              ))}
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              padding: Spacing.between,
              paddingRight: 8,
            }}
          >
            <View style={{ alignItems: "center", marginLeft: 8 }}>
              <Icon name="k-movie" size={24} color={Colors.tabs.active} />
            </View>
            <View
              style={{
                alignItems: "center",
                flex: 1,
                marginLeft: Spacing.between,
              }}
            >
              <Text type="subtitleStrong">{text}</Text>
            </View>
            <Icon name="f-chevron-right" size={28} color={Colors.secondary} />
          </View>
        </View>
      </View>
    </Link>
  );
}

function Post({ post }) {
  if (post.post_type === "kino") {
    return <NewMovies text={post.text} data={post.data} />;
  } else if (post.post_type === "text") {
    return <Body>{post.text}</Body>;
  } else {
    return null;
  }
}

export { Post };
