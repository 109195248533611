import React from "react";
import { Grid } from "shared/components/Grid";
import { ProfileCircle, AllProfilesCircle } from "shared/components/ProfileRow";

function ProfilesGrid({ section }) {
  const profiles = section.items;

  return (
    <Grid>
      {profiles.map((profile) => (
        <Grid.Item key={profile.id}>
          <ProfileCircle profile={profile} />
        </Grid.Item>
      ))}
      <AllProfilesCircle />
    </Grid>
  );
}
export { ProfilesGrid };
