import React from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Colors, Spacing } from "shared/utils/theme";
import { HotButton } from "shared/components/HotButton";
import { Linkable } from "shared/components/Link";
import { ImageSourceType } from "shared/components/Image/types";
import { CloudinaryImage } from "../../Image";
import { AppStoreButtons } from "./AppStoreButtons";
import { Icon } from "../../Icon";

interface ButtonConfig {
  link: Linkable;
  gradient?: string;
  label: string;
  large?: boolean;
}

function HeroHeadline({ text }) {
  return (
    <View style={{ alignItems: "center" }}>
      <Text
        dataSet={{ hackMobileFontSmaller: true }}
        style={{
          textAlign: "center",
          marginTop: Spacing.between * 2,
          marginBottom: Spacing.between,
        }}
        type="huge"
      >
        {text}
      </Text>
    </View>
  );
}

function ButtonRow({
  justify,
  buttons,
}: {
  buttons: ButtonConfig[];
  justify: "flex-start" | "center" | "flex-end";
}) {
  return (
    <View
      style={{ flexDirection: "row", width: "100%", justifyContent: justify }}
    >
      {buttons.map((button) => (
        <HotButton
          style={{ marginRight: Spacing.between * 2 }}
          large={button.large}
          {...button.link}
          gradient={button.gradient}
        >
          {button.label}
        </HotButton>
      ))}
    </View>
  );
}

function Spacer({ size = 2 }) {
  return <View style={{ height: Spacing.betweenHalf * size }} />;
}

function HeroParagraph({ title, text }) {
  return (
    <View style={{ alignItems: "flex-start" }}>
      <Text style={{ marginBottom: Spacing.between }} type="huge">
        {title}
      </Text>
      <Text type="longTitle">{text}</Text>
    </View>
  );
}

export function WebShopify() {
  return (
    <div
      style={{ backgroundColor: "white", paddingTop: 32 }}
      dangerouslySetInnerHTML={{
        __html: `
  <div id='collection-component-1702739497370'></div>
<script type="text/javascript">
/*<![CDATA[*/
(function () {
  var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: '8dfb7e-6.myshopify.com',
      storefrontAccessToken: '10d4ad320040e0fb133f8548aa6be919',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('collection', {
        id: '612617683291',
        node: document.getElementById('collection-component-1702739497370'),
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
        options: {
  "product": {
    "styles": {
      "product": {
        "@media (min-width: 601px)": {
          "max-width": "calc(25% - 20px)",
          "margin-left": "20px",
          "margin-bottom": "50px",
          "width": "calc(25% - 20px)"
        },
        "img": {
          "height": "calc(100% - 15px)",
          "position": "absolute",
          "left": "0",
          "right": "0",
          "top": "0"
        },
        "imgWrapper": {
          "padding-top": "calc(75% + 15px)",
          "position": "relative",
          "height": "0"
        }
      }
    },
    "text": {
      "button": "Add to cart"
    }
  },
  "productSet": {
    "styles": {
      "products": {
        "@media (min-width: 601px)": {
          "margin-left": "-20px"
        }
      }
    }
  },
  "modalProduct": {
    "contents": {
      "img": false,
      "imgWithCarousel": true,
      "button": false,
      "buttonWithQuantity": true
    },
    "styles": {
      "product": {
        "@media (min-width: 601px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px"
        }
      }
    },
    "text": {
      "button": "Add to cart"
    }
  },
  "option": {},
  "cart": {
    "text": {
      "total": "Subtotal",
      "button": "Checkout"
    }
  },
  "toggle": {}
},
      });
    });
  }
})();
/*]]>*/
</script>
  
    `,
      }}
    />
  );
}

function Step({ title, text }) {
  return (
    <View style={{ flex: 1, margin: Spacing.between }}>
      <Text type="longTitle">{title}</Text>
      <Text type="body">{text}</Text>
    </View>
  );
}

function SignupSteps() {
  return (
    <View nativeID="signup" style={{ alignItems: "center" }}>
      <Text dataSet={{ hackTextCenterLeft: true }} type="largeTitle">
        Schön, dass ihr dabei seid 🎉
      </Text>
      <View
        // @ts-ignore
        dataSet={{ hackColumnRow: true }}
        style={{ marginVertical: Spacing.between * 2 }}
      >
        <Step
          title="1. Registrierung"
          text="Erstellt euch über folgenden Button euren kostenlosen Veranstalteraccount. Ihr könnt auch schon direkt loslegen und eure ersten Veranstaltungen erstellen. "
        />
        <Step
          title="2. Verifizierung"
          text="Bevor eure Veranstaltungen öffentlich sichtbar sind, verifizieren wir euren Account. Dies dauert in der Regel wenige Stunden. "
        />
        <Step
          title="3. Fertig!"
          text="Alles geschafft! Euer Profil ist jetzt öffentlich und wir freuen uns auf tolle Veranstaltungen von euch!"
        />
      </View>
    </View>
  );
}

function MiddleHeadline({ text }) {
  return (
    <View style={{ alignItems: "center" }}>
      <Text
        dataSet={{ hackTextCenterLeft: true }}
        type="almostHuge"
        style={{ marginBottom: Spacing.between * 2 }}
      >
        {text}
      </Text>
    </View>
  );
}

function SideBySidePhone({
  title,
  features = [],
  text,
  showAppStoreButtons = true,
  reverse = false,
  image = {
    handle: "manual/screenshot_landing",
  },
}: {
  title: string;
  text?: string;
  features: string[];
  showAppStoreButtons?: boolean;
  reverse?: boolean;
  image?: ImageSourceType;
}) {
  return (
    <View
      style={{
        flexDirection: reverse ? "row-reverse" : "row",
        flexWrap: "wrap",
        alignItems: "center",
        marginBottom: Spacing.between,
      }}
    >
      <View style={{ flex: 1, height: 640, minWidth: 340 }}>
        <CloudinaryImage
          important={true}
          image={image}
          width={340}
          height={640}
          cover={false}
          style={{ flex: 1, width: "100%" }}
        />
      </View>
      <View
        style={{
          flex: 2,
          minWidth: 300,
          marginLeft: reverse ? 0 : Spacing.between * 2,
          marginRight: reverse ? Spacing.between * 2 : 0,
        }}
      >
        <Text
          dataSet={{ hackTextCenterLeft: true }}
          type="almostHuge"
          style={{ marginBottom: Spacing.between }}
        >
          {title}
        </Text>
        {text && (
          <View style={{ paddingVertical: 16 }}>
            <Text type="body">{text}</Text>
          </View>
        )}
        {features.map((feature) => (
          <View
            style={{
              paddingVertical: 8,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 16,
                backgroundColor: Colors.ios.purple,
                justifyContent: "center",
                alignItems: "center",
                marginRight: 10,
              }}
            >
              <View
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                  backgroundColor: Colors.transparent.primary[3],
                }}
              />
            </View>
            <Text type="titleBar">{feature}</Text>
          </View>
        ))}
        {showAppStoreButtons && (
          <View style={{ marginTop: Spacing.betweenHalf }}>
            <AppStoreButtons />
          </View>
        )}
      </View>
    </View>
  );
}

function TextAndButtonRowNew({
  text,
  button,
}: {
  text: string;
  button: ButtonConfig;
}) {
  return (
    <View style={{ alignItems: "center" }}>
      <Text type="longTitle">{text}</Text>
      <View style={{ flex: 1, marginTop: Spacing.between * 2 }}>
        <HotButton
          large={button.large}
          gradient={button.gradient}
          {...button.link}
        >
          {button.label}
        </HotButton>
      </View>
    </View>
  );
}

function TextAndButtonRow({
  text,
  button,
}: {
  text: string;
  button: ButtonConfig;
}) {
  return (
    <View
      // @ts-ignore
      dataSet={{ hackColumnRow: true, hackTextCenterLeft: true }}
      style={{ alignItems: "center" }}
    >
      <Text
        style={{
          flex: 2,
          marginVertical: Spacing.between * 4,
          marginRight: Spacing.between,
        }}
        type="longTitle"
      >
        {text}
      </Text>
      <View style={{ flex: 1 }}>
        <HotButton
          large={button.large}
          gradient={button.gradient}
          {...button.link}
        >
          {button.label}
        </HotButton>
      </View>
    </View>
  );
}

export {
  ButtonRow,
  HeroHeadline,
  MiddleHeadline,
  SideBySidePhone,
  TextAndButtonRow,
  Spacer,
  SignupSteps,
  HeroParagraph,
};
