import { openScreen_UNSAFE_GLOBAL } from "shared/components/Link";
import { track } from "shared/utils/tracking";
import { reloadApp } from "shared/utils/platform";
import { Colors } from "shared/utils/theme";
import { sendDebug } from "shared/components/Error";

type ActionType = keyof typeof dynamicActions;

export interface DynamicAction {
  order?: number;
  type: ActionType;
  config?: any;
}

const dynamicActions = {
  share: async ({
    id,
    universal,
    shareOptions,
    linkProperties,
    controlParams,
  }) => {},
  shareAppURL: async () => {
    console.info("not implemented");
  },
  shareResource: async ({
    resourceId,
    resourceType,
    title,
    campaign = undefined,
  }) => {},
  shareKULTA: () => {},
  link: ({ resourceType, resourceId }) => {
    openScreen_UNSAFE_GLOBAL(resourceType, resourceId);
  },
  enablePush: () => console.info("push is not implemented"),
  setUserProp: ({ key, value }) => {},
  setFlag: ({ id, value }) => {},
  toggleFlag: ({ id }) => {},
  followProfile: ({ profileID }) => {},
  save: ({ occurrenceId }) => {},
  reloadFeed: () => {},
  restartApp: () => reloadApp(),
  track: ({ event, props }) => track(event, props),
  sendDebug: () => sendDebug(),
  notify: ({
    text = "",
    icon = "info",
    lottie = undefined,
    haptic = undefined,
    hideAfter = 2000,
    color = Colors.generic.white,
  }) => console.info("notification is not implemented"),
};

function execute({
  actions = [],
  context = undefined,
}: {
  actions: DynamicAction[];
  context?: any;
}) {
  let sorted = actions.sort(({ order: a = 0 }, { order: b = 0 }) => a - b);
  sorted.forEach((action) =>
    // @ts-ignore
    dynamicActions[action.type](action.config, context)
  );
}

export { execute };
