import React from "react";
import {
  TimelineTile,
  NoticeSection,
  OccurrenceCard,
  TimelineHint,
  FeatureHint,
  FeedPost,
} from "./OtherSections";
import {
  CollagePicks,
  CollageRow,
  OccurrencePicks,
  OccurrenceRow,
} from "./EventRowSections";
import { CountdownSection } from "./CountdownSection";
import { ManualFollowRecs } from "./FollowRecs";
import { SectionData } from "../Section";
import { FeatureItem } from "shared/components/FeatureItem";
import {
  HeroHeadline,
  MiddleHeadline,
  TextAndButtonRow,
  SideBySidePhone,
  Spacer,
  HeroParagraph,
  SignupSteps,
  WebShopify,
} from "./LandingPage";
import { AppStoreButtons } from "./AppStoreButtons";
import { FeatureCards } from "./FeatureCards";
import { DefaultSharingMeta } from "shared/components/web/SharingMeta";
import { ButtonRow } from "./LandingPage";
import { Ad } from "./Ad";
import { ProfilesGrid } from "./ProfilesGrid";
import { CalendarFilter } from "shared/components/CalendarFilter";
import { Headline } from "./Headline";
import { LatestPosts } from "./LatestPosts";
import { CategoriesCalendar } from "./CategoriesCalendar";

function wrap<T>(Component: (props: T) => React.ReactElement) {
  return function Wrapped({ section }: { section: SectionData<T> }) {
    return <Component {...section.config} />;
  };
}

const layouts = {
  profilesGrid: ProfilesGrid,
  webAppStoreButtons: wrap(AppStoreButtons),
  ad: wrap(Ad),
  calendarFilter: CategoriesCalendar,
  webSideBySidePhone: wrap(SideBySidePhone),
  webSharingMeta: wrap(DefaultSharingMeta),
  landingFeatureCards: wrap(FeatureCards),
  landingTextAndButtonRow: wrap(TextAndButtonRow),
  landingMiddleHeadline: wrap(MiddleHeadline),
  landingHeroHeadline: React.memo(wrap(HeroHeadline)),
  landingButtonRow: wrap(ButtonRow),
  landingHeroParagraph: wrap(HeroParagraph),
  webSignupSteps: wrap(SignupSteps),
  spacer: wrap(Spacer),
  feature: React.memo(wrap(FeatureItem)),
  featureHint: React.memo(FeatureHint),
  collageRow: React.memo(CollageRow),
  collagePicks: React.memo(CollagePicks),
  occurrenceRow: React.memo(OccurrenceRow),
  occurrencePicks: React.memo(OccurrencePicks),
  notice: React.memo(NoticeSection),
  timelineTile: React.memo(TimelineTile),
  countdown: React.memo(CountdownSection),
  manualFollowRecs: React.memo(ManualFollowRecs),
  occurrenceCard: OccurrenceCard,
  timelineHint: TimelineHint,
  post: FeedPost,
  headline: Headline,
  latestPosts: LatestPosts,
  webShopify: WebShopify,
};

export type LayoutsType = typeof layouts;

export { layouts };
