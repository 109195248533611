import React from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";

function NotFound() {
  return (
    <View>
      <Text type="largeTitle">NotFound</Text>
    </View>
  );
}
export { NotFound };
