import React from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Spacing, Colors, Radius } from "shared/utils/theme";

function FeatureCards({ features, maxWidth = 340, columns = 2 }) {
  return (
    <View
      dataSet={{ [`hackColumns${columns}`]: true }}
      // @ts-ignore
      style={{
        display: "grid",
        width: "100%",
        gridGap: Spacing.between * 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {features.map(({ title, text }) => (
        <View
          key={title}
          style={{
            padding: Spacing.between * 2,
            backgroundColor: Colors.card,
            borderRadius: Radius.round,
            shadowColor: Colors.shadow,
            shadowOffset: { width: 4, height: 4 },
            shadowRadius: 36,
            height: "100%",
          }}
        >
          <Text type="largeTitle">{title}</Text>
          <Text type="body">{text}</Text>
        </View>
      ))}
    </View>
  );
}

export { FeatureCards };
