import React from "react";
import { Link } from "shared/components/Link";
import { View } from "react-native";
import { Spacing } from "shared/utils/theme";

function AppStoreButtons() {
  return (
    <View
      style={{
        alignItems: "center",
        flexDirection: "row",
        marginLeft: -14,
      }}
    >
      <Link
        resourceType="url"
        modal={true}
        resourceId="https://link.kulta.app/goappleappstore"
      >
        <img
          style={{ height: 64, margin: Spacing.between }}
          alt="Im Apple App Store laden"
          src="/app-store.png"
        />
      </Link>
      <Link
        resourceType="url"
        modal={true}
        resourceId="https://link.kulta.app/gogoogleplaystore"
      >
        <img
          style={{ height: 64, margin: Spacing.between }}
          alt="Im Google Play Store laden"
          src="/play-store.png"
        />
      </Link>
    </View>
  );
}

export { AppStoreButtons };
