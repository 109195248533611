import gql from "graphql-tag";

const NOTICE_FRAGMENT = gql`
  fragment NoticeFragment on notices {
    id
    title
    text
    gradientFrom
    gradientTo
    buttons
    link_resourceId
    link_resourceType
  }
`;

export { NOTICE_FRAGMENT };
