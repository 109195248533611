import React from "react";
import { Link, Linkable } from "shared/components/Link";
import { CloudinaryImage } from "shared/components/Image";
import { Card } from "shared/components/EventCard";
import { ImageSourceType } from "shared/components/Image/types";
import { Dynamic, Spacing } from "shared/utils/theme";

function Ad({ image, link }: { image: ImageSourceType; link: Linkable }) {
  return (
    <Link {...link}>
      <Card
        style={{
          width: Dynamic.width - Spacing.between * 2,
          height: Dynamic.width - Spacing.between * 2,
        }}
      >
        <CloudinaryImage
          important={true}
          image={image}
          width={Dynamic.width - Spacing.between * 2}
          height={Dynamic.width - Spacing.between * 2}
        />
      </Card>
    </Link>
  );
}

export { Ad };
