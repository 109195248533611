import gql from "graphql-tag";

const PROFILE_ROW_FRAGMENT = gql`
  fragment ProfileRowFragment on profiles {
    name
    id
    tagline
    image_handle
    slug
    website
    phone
    followed
    claimed
  }
`;

const SMALL_PROFILE_ROW_FRAGMENT = gql`
  fragment SmallProfileRowFragment on profiles {
    name
    id
    slug
    tagline
    website
    phone
    image_handle
    claimed
  }
`;

const PROFILE_ROW_FRAGMENT_ON_OCCURRENCE = gql`
  fragment ProfileRowFragmentOnOccurrence on occurrences {
    event {
      profile {
        ...ProfileRowFragment
      }
    }
  }
  ${PROFILE_ROW_FRAGMENT}
`;

const SMALL_PROFILE_ROW_FRAGMENT_ON_OCCURRENCE = gql`
  fragment SmallProfileRowFragmentOnOccurrence on occurrences {
    event {
      profile {
        ...SmallProfileRowFragment
      }
    }
  }
  ${SMALL_PROFILE_ROW_FRAGMENT}
`;

export {
  PROFILE_ROW_FRAGMENT,
  SMALL_PROFILE_ROW_FRAGMENT,
  SMALL_PROFILE_ROW_FRAGMENT_ON_OCCURRENCE,
  PROFILE_ROW_FRAGMENT_ON_OCCURRENCE,
};
