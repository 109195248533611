import React from "react";
import { StyleSheet, View } from "react-native";
import { Spacing } from "shared/utils/theme";
import { GridProps, GridItemProps } from "./types";

const styles = StyleSheet.create({
  gridColumn: {
    // @ts-ignore
    gridColumn: "span 1",
    maxWidth: 200,
  },
  grid: {
    // @ts-ignore
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(108px, 1fr))",
    width: "100%",
    marginTop: Spacing.betweenHalf,
    gridGap: Spacing.between,
  },
});

function Grid({ children }: GridProps) {
  return <View style={styles.grid}>{children}</View>;
}

function Item({ children }: GridItemProps) {
  return <View style={styles.gridColumn}>{children}</View>;
}

Grid.Item = Item;

export { Grid };
