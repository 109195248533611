import React from "react";
import { View } from "react-native";
import { Text } from "shared/components/Text";
import { Colors, Radius, Spacing } from "shared/utils/theme";
import { Link } from "shared/components/Link";
import { Icon } from "shared/components/Icon";
import { LinearGradient } from "shared/components/Gradient";
import { NoticeWithData } from "shared/components/Notice";
import { SectionData } from "../Section";
import { FeedCard } from "shared/components/EventCard";
import { Touchable } from "shared/components/Touchable";
import { t } from "shared/utils/config";
import { Post } from "shared/components/Post";
import { useNavigation } from "@react-navigation/native";

function TimelineHint() {
  const navigator = useNavigation();
  return (
    <Touchable
      onPress={() => navigator.navigate({ key: "Profile" })}
      style={{ flexDirection: "row", alignItems: "center" }}
    >
      <View style={{ alignItems: "center", marginLeft: Spacing.between }}>
        <Icon name="k-tab-profiles" size={40} color={Colors.tabs.active} />
        <Text
          type="captionStrong"
          color={Colors.tabs.active}
          style={{ marginTop: 4 }}
        >
          Gefolgt
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          marginLeft: Spacing.between + 4,
          marginRight: Spacing.betweenHalf,
        }}
      >
        <Text type="subtitleStrong">
          {t("TIMELINE_HINT_TITLE", "NEU! NEU! NEU!")}
        </Text>
        <Text type="subtitle">
          {t(
            "TIMELINE_HINT",
            `Hier findest du jetzt immer die neusten Veranstaltungen von deinen gefolgten Profilen.`
          )}
        </Text>
      </View>
      <Icon name="f-chevron-right" size={28} color={Colors.secondary} />
    </Touchable>
  );
}

function FeedPost({ section: { config: post } }) {
  return <Post post={post} />;
}

function FeatureHint({
  section: {
    config: { icon, title, text, resourceType, resourceId },
  },
}) {
  return (
    <Link
      resourceType={resourceType}
      resourceId={resourceId}
      style={{
        flexDirection: "row",
        paddingHorizontal: Spacing.betweenHalf,
        alignItems: "center",
        paddingBottom: Spacing.between,
      }}
    >
      <Icon name={icon} size={40} color={Colors.accent} />
      <View
        style={{
          flex: 1,
          marginLeft: Spacing.between + 4,
          marginRight: Spacing.betweenHalf,
        }}
      >
        <Text type="subtitleStrong">{title}</Text>
        <Text type="subtitle">{text}</Text>
      </View>
      <Icon name="f-chevron-right" size={28} color={Colors.secondary} />
    </Link>
  );
}

function OccurrenceCard({ section }) {
  if (section.items[0] && section.itemsType === "occurrence") {
    return <FeedCard data={section.items[0]} />;
  } else {
    return null;
  }
}

function TimelineTile({
  section,
}: {
  section: SectionData<{ unread?: number }>;
}) {
  return (
    <Link
      resourceType="timeline"
      prefetch={true}
      track={{
        name: "Tapped Timeline Tile",
        props: {
          actionType: "tapLink",
          resourceType: "timeline",
          extraName: "unreadCount",
          extraValue: String(section?.config?.unread),
        },
      }}
    >
      <LinearGradient
        colors={["#a644d4", "#8144E1"]}
        style={{
          borderRadius: Radius.round,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "blue",
          paddingLeft: Spacing.between,
        }}
      >
        <View style={{ flex: 1 }}>
          <Text color="white" type="longTitle">
            Deine Timeline
          </Text>
          <Text color="white" type="body">
            Die neusten Posts von Profilen denen du folgst
          </Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {section?.config?.unread > 0 && (
            <View
              style={{
                backgroundColor: "#ffffff77",
                borderRadius: Radius.medium,
                paddingVertical: 4,
                paddingHorizontal: Spacing.betweenHalf,
              }}
            >
              <Text color="white" type="smallTitle">
                {section?.config?.unread}
              </Text>
            </View>
          )}
          <Icon color="#ffffffaa" size={32} name="f-chevron-right" />
        </View>
      </LinearGradient>
    </Link>
  );
}

function NoticeSection({ section }: { section: SectionData }) {
  if (section.items[0].__typename === "notices") {
    return <NoticeWithData data={section.items[0]} />;
  } else {
    return null;
  }
}

export {
  TimelineHint,
  TimelineTile,
  FeatureHint,
  OccurrenceCard,
  FeedPost,
  NoticeSection,
};
