import React from "react";
import { View, StyleSheet } from "react-native";
import gql from "graphql-tag";
import { Text } from "shared/components/Text";
import { Spacing, Colors, Radius } from "shared/utils/theme";
import { LinearGradient } from "shared/components/Gradient";
import { Button } from "shared/components/Button";
import { Link } from "shared/components/Link";
import { NOTICE_FRAGMENT } from "./fragment";
import { Loadable } from "shared/components/Loadable";
import { Icon } from "shared/components/Icon";
import { execute, DynamicAction } from "shared/utils/dynamicActions";

const NOTICE_QUERY = gql`
  query NoticeQuery($id: Int!) {
    item: notices_by_pk(id: $id) {
      ...NoticeFragment
    }
  }
  ${NOTICE_FRAGMENT}
`;

const styles = StyleSheet.create({
  cardContainer: {
    elevation: 2,
    borderRadius: Radius.round,
    overflow: "hidden",
    backgroundColor: Colors.background,
  },
  shadowContainer: {
    shadowColor: Colors.generic.black,
    shadowOpacity: 0.08,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: Spacing.between,
    borderRadius: Radius.round,
  },
});

function NoticeLoadable({ id }) {
  return (
    <Loadable
      component={NoticeWithData}
      query={NOTICE_QUERY}
      variables={{ id }}
    />
  );
}

function NoticeWithData({
  data: {
    title = "",
    text = "",
    gradientFrom = "#3A30E5",
    gradientTo = "#7144E1",
    link_resourceType = undefined,
    link_resourceId = undefined,
    buttons = [],
  },
}) {
  return (
    <Notice
      title={title}
      text={text}
      link={
        link_resourceType
          ? { resourceType: link_resourceType, resourceId: link_resourceId }
          : undefined
      }
      actions={buttons}
      colors={
        gradientFrom && gradientTo ? [gradientFrom, gradientTo] : undefined
      }
    ></Notice>
  );
}

function Notice({
  title,
  text,
  actions = [],
  colors = ["#3A30E5", "#7144E1"],
  children = undefined,
  link = undefined,
}: {
  title: string;
  text: string;
  actions: DynamicAction[];
  colors?: string[];
  children?: any;
  link?: { resourceType: string; resourceId: string };
}) {
  const LinkComp = link ? Link : View;
  return (
    <LinkComp
      style={styles.shadowContainer}
      // @ts-ignore
      resourceType={link?.resourceType}
      resourceId={link?.resourceId}
    >
      <View style={styles.cardContainer}>
        <LinearGradient
          colors={colors}
          style={{ padding: Spacing.betweenHalf }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ padding: Spacing.betweenHalf, flex: 1 }}>
              <Text
                type={"longTitle"}
                color={Colors.generic.white}
                style={{ opacity: 0.8 }}
              >
                {title}
              </Text>
              <Text
                type={"subtitle"}
                color={Colors.generic.white}
                style={{ opacity: 0.8 }}
              >
                {text}
              </Text>
            </View>
            {link && (
              <Icon
                name="f-chevron-right"
                color={Colors.generic.white}
                size={28}
              />
            )}
          </View>
          <View style={{ flexDirection: "row" }}>
            {children
              ? children
              : actions
              ? actions.map((action: any, index) => (
                  <Button
                    key={index}
                    background={"#ffffffee"}
                    foreground={colors[1]}
                    children={action.text}
                    onPress={
                      action.onPress
                        ? action.onPress
                        : action.actions
                        ? (event) =>
                            execute({
                              actions: action.actions,
                              context: { event },
                            })
                        : () => {}
                    }
                    style={[{ margin: Spacing.betweenHalf, flex: 1 }]}
                  />
                ))
              : null}
          </View>
        </LinearGradient>
      </View>
    </LinkComp>
  );
}

export { Notice, NoticeWithData, NoticeLoadable };
