import React from "react";
import { View } from "react-native";
import { Spacing, Dynamic } from "shared/utils/theme";
import { Text } from "shared/components/Text";
import { Icon } from "shared/components/Icon";

function FeatureItem({ icon, title, text, color }) {
  return (
    <View
      style={{
        flexDirection: "row",
        paddingVertical: Dynamic.isSmallScreen
          ? Spacing.betweenHalf
          : Spacing.between,
        alignItems: "center",
      }}
    >
      <View style={{ padding: Spacing.between }}>
        <Icon color={color} name={icon} size={48} />
      </View>
      <View style={{ flex: 1, marginLeft: Spacing.betweenHalf }}>
        <Text type="longTitle">{title}</Text>
        <Text type="body">{text}</Text>
      </View>
    </View>
  );
}

export { FeatureItem };
