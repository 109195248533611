import React from "react";
import { View, StyleProp, ViewStyle, Platform } from "react-native";
import { Text } from "shared/components/Text";
import { Spacing, Colors } from "shared/utils/theme";
import { Link, Linkable, ResourceType } from "shared/components/Link";
import { Icon } from "shared/components/Icon";
import {
  FeedSectionFragment_rd_occurrence,
  FeedSectionFragment_rd_collage,
  FeedSectionFragment_rd_notice,
  FeedSectionFragment_rd_profile,
} from "./_gql/FeedSectionFragment";
import { LayoutsType } from "./layouts";

export interface SectionData<TConfig = {}> {
  id: string;
  layout: keyof LayoutsType;
  order?: number;
  name?: string;
  config?: TConfig;
  link_resourceID?: string;
  link_resourceType?: ResourceType;
  link_modal?: boolean;
  items?:
    | FeedSectionFragment_rd_occurrence[]
    | FeedSectionFragment_rd_collage[]
    | FeedSectionFragment_rd_notice[]
    | FeedSectionFragment_rd_profile[];
  itemsType?: "collage" | "profile" | "notice" | "occurrence";
}

function Section({
  title,
  link,
  style,
  children,
}: {
  title?: string;
  link?: Linkable;
  style?: StyleProp<ViewStyle>;
  children?: any;
}) {
  const LinkComp = link ? Link : View;
  return (
    <View style={style}>
      {title && (
        <LinkComp
          {...link}
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline",
            paddingRight: Spacing.betweenHalf,
            paddingBottom: Spacing.between,
          }}
        >
          <Text
            style={{
              flex: 1,
              marginBottom: Platform.select({
                web: Spacing.betweenHalf,
                default: 0,
              }),
            }}
            type={Platform.select({ web: "almostHuge", default: "title" })}
          >
            {title}
          </Text>
          {link && (
            <Icon
              name="f-chevron-right"
              color={Colors.transparent.primary[4]}
              size={22}
            />
          )}
        </LinkComp>
      )}
      {children}
    </View>
  );
}

export { Section };
