import React from "react";
import { View } from "react-native";
import { Spacing } from "shared/utils/theme";
import { ProfileRowLarge } from "shared/components/ProfileRow";
import take from "lodash/take";

function ManualFollowRecs({ section }) {
  const limit = section?.config?.limit || 4;
  const profiles = section.items;
  const showable = take(
    profiles.filter(({ followed }) => !followed),
    limit
  );

  if (showable.length > 0) {
    return (
      <View>
        {showable.map((profile: any) => (
          <ProfileRowLarge
            animate={true}
            profile={profile}
            key={profile.id}
            style={{ marginVertical: Spacing.betweenHalf }}
          />
        ))}
      </View>
    );
  } else {
    throw "Not enough rows to display";
  }
}

export { ManualFollowRecs };
