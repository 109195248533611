import React, { useMemo } from "react";
import { View, ScrollView, FlatList } from "react-native";
import { TrackData } from "shared/utils/tracking";
import partition from "lodash/partition";
import gql from "graphql-tag";
import { FEED_SECTION_FRAGMENT } from "./fragment";
import { Loadable } from "shared/components/Loadable";
import { layouts } from "./layouts";
import { HideErrorBoundary } from "shared/components/Error";
import { Spacing } from "shared/utils/theme";
import { capture } from "shared/utils/tracking";
import { Section, SectionData } from "./Section";

const FEED_QUERY = gql`
  query Feed($day: timestamptz, $id: String, $userID: String) {
    item: feed_fast(
      args: { arg_feedtype: $id, arg_day: $day, extra: { asUser: $userID } }
      order_by: { order: asc }
    ) {
      ...FeedSectionFragment
    }
  }
  ${FEED_SECTION_FRAGMENT}
`;

function makeItemsReal(
  items: string[],
  itemsType: string,
  resources: Record<string, any>
) {
  const realItems =
    items &&
    items
      .map((id) => {
        try {
          const resource = resources.find(
            (r) =>
              String(r.id) === String(id) && r.re_resourceType === itemsType
          );
          const data = resource[`rd_${itemsType}`];
          return data;
        } catch (e) {
          capture("Silently Failed to make item real", {
            extra: {
              itemID: id,
              itemsType,
              error: JSON.stringify(e),
            },
          });
          return undefined;
        }
      })
      .filter(Boolean);
  return realItems;
}

function denormalizeFeedSections(data) {
  const [resources, sections] = partition(data, (d) => d.isResource);
  const denormalized = sections
    .sort((a, b) => a.order - b.order)
    .map((section) => {
      try {
        const { items, itemsType, ...rest } = section;
        const realItems = makeItemsReal(items, itemsType, resources);

        return {
          ...rest,
          items: realItems,
          itemsType,
        };
      } catch (e) {
        capture("silently failed to denormalize feed section", {
          extra: { error: JSON.stringify(e) },
        });
        return undefined;
      }
    })
    .filter(Boolean);
  return denormalized;
}

function FeedLoadable({ id, day = new Date(), userID = "public" }) {
  return (
    <Loadable
      query={FEED_QUERY}
      variables={{
        id,
        day,
        userID: userID,
      }}
      component={PlainFeed}
    />
  );
}

const FeedSection: React.FC<any> = React.memo(
  ({ section }: { section: SectionData }) => {
    const Component = layouts[section.layout];
    if (Component) {
      return (
        <HideErrorBoundary key={section.id}>
          <TrackData
            props={{ sourceId: section.id, sourceType: "feedSection" }}
          >
            <Section
              link={
                section.link_resourceType && {
                  resourceType: section.link_resourceType,
                  resourceId: section.link_resourceID,
                  modal: section.link_modal,
                }
              }
              title={section.name}
              style={{ paddingVertical: Spacing.between, width: "100%" }}
            >
              {/* @ts-ignore */}
              <Component section={section} />
            </Section>
          </TrackData>
        </HideErrorBoundary>
      );
    } else {
      return null;
    }
  }
);

function _ScrollFeed({ data, scrollRef }) {
  const sections = useMemo(() => denormalizeFeedSections(data), [data]);

  return (
    <ScrollView
      ref={scrollRef}
      style={{
        flex: 1,
        paddingBottom: Spacing.between,
      }}
    >
      {sections.map((section) => (
        <FeedSection key={section.id} section={section} />
      ))}
    </ScrollView>
  );
}

function PlainFeed({ data }) {
  const sections = useMemo(() => denormalizeFeedSections(data), [data]);
  return (
    <View
      style={{
        flex: 1,
        paddingBottom: Spacing.between,
      }}
    >
      {sections.map((section) => (
        <FeedSection key={section.id} section={section} />
      ))}
    </View>
  );
}

function DirectFeed({ sections }) {
  return (
    <View style={{ flex: 1 }}>
      {sections.map((section) => (
        <FeedSection key={section.id} section={section} />
      ))}
    </View>
  );
}

function _Feed({
  data,
  scrollRef = null,
  refetch = (newVars) => {},
  fetching = false,
}) {
  const sections = useMemo(() => denormalizeFeedSections(data), [data]);

  return (
    <FlatList
      onRefresh={() => refetch({ refresh: true })}
      refreshing={fetching}
      initialNumToRender={3}
      style={{ flex: 1 }}
      ref={scrollRef}
      contentContainerStyle={{
        paddingHorizontal: Spacing.between + 4,
        paddingBottom: Spacing.between,
      }}
      scrollToOverflowEnabled={true}
      data={sections}
      keyExtractor={(i) => String(i.id)}
      renderItem={({ item, index }) => <FeedSection section={item} />}
    />
  );
}

const Feed = React.memo(_Feed);
const ScrollFeed = React.memo(_ScrollFeed);

export { Feed, FeedLoadable, PlainFeed, ScrollFeed, DirectFeed };
