import React from "react";
import { TextStyle } from "react-native";
import { Colors } from "shared/utils/theme";
import { Text, TextStyleType } from "shared/components/Text";
import { Spacing } from "shared/utils/theme";
import { SectionData } from "../Section";

function Headline({
  section: {
    config: {
      text = "",
      type = "longTitle",
      style = {},
      center = true,
      highlight = [],
    },
  },
}: {
  section: SectionData<{
    text?: string;
    type?: TextStyleType;
    style?: TextStyle;
    center?: boolean;
    highlight?: string[];
  }>;
}) {
  return (
    <Text
      type={type}
      style={{
        textAlign: center ? "center" : "left",
        ...style,
      }}
    >
      {text.split(" ").map((word) => (
        <Text
          key={word}
          color={highlight.includes(word) ? Colors.accent : Colors.primary}
        >
          {word}{" "}
        </Text>
      ))}
    </Text>
  );
}

export { Headline };
